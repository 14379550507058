var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-0 ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-12 pa-0"},[_c('ConfirmationModal',{ref:"activeToggleConfirmationModal",attrs:{"title":_vm.confirmationTitle,"subtitle":"","message":_vm.confirmationMessage,"confirmColor":"error darken-1"}}),_c('DataTableLayout',{attrs:{"options":_vm.layoutOptions},on:{"search":function($event){_vm.search = $event},"add":_vm.openForm},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"search":_vm.search,"headers":_vm.headers,"loading":false,"items":_vm.mostCurrentReports,"loading-text":"Searching... Please wait"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NoData'),_c('h2',{staticClass:"mb-12 subtitle-1"},[_vm._v("No data found")])]},proxy:true},{key:"item.messageTypeIndicator",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.messageTypeMap[item.messageTypeIndicator])+" ")])]}},{key:"item.institution",fn:function(ref){
var institution = ref.item.institution;
return [_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(_vm._s(institution.taxIdentificationNumber))]),_c('b',[_vm._v(_vm._s(institution.name))]),_c('address',{attrs:{"href":("tel: " + (institution.contactNumber))}},[_vm._v(" "+_vm._s(((institution.street) + ", " + (institution.city) + ", " + (institution.countrySubEntity) + ", " + (_vm.countryMap[institution.country])))+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.user.name))]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":("tel: " + (item.user.contactNumber))}},[_vm._v(_vm._s(item.user.contactNumber))]),_c('br'),_c('a',{attrs:{"href":("mailto: " + (item.user.email))}},[_vm._v(_vm._s(item.user.email))])]):_vm._e()]}},{key:"item.recipientCountry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.countryMap[item.recipientCountry])+" ")]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.messageTypeIndicator === 'CRS703')?_c('DataTableActions',{attrs:{"actions":_vm.noDataActions,"data":{ index: index, item: item }}}):_c('DataTableActions',{attrs:{"actions":_vm.fullActions,"data":{ index: index, item: item }}})]}}],null,true)})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }