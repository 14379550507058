<template>
  <v-row justify="center" class="px-0 ma-0">
    <v-col class="col-12 pa-0">
      <!-- Modals -->
      <ConfirmationModal
        ref="activeToggleConfirmationModal"
        :title="confirmationTitle"
        subtitle=""
        :message="confirmationMessage"
        confirmColor="error darken-1"
      />

      <DataTableLayout
        @search="search = $event"
        :options="layoutOptions"
        @add="openForm"
      >
        <!-- Use to add addition actions such as buttons-->
        <template #actions> </template>

        <!-- content to display -->
        <template #content>
          <v-data-table
            :search="search"
            class="elevation-0"
            :headers="headers"
            :loading="false"
            :items="mostCurrentReports"
            loading-text="Searching... Please wait"
          >
            <template v-slot:no-data>
              <NoData />
              <h2 class="mb-12 subtitle-1">No data found</h2>
            </template>

            <template v-slot:[`item.messageTypeIndicator`]="{ item }">
              <p class="pb-0 mb-0">
                {{ messageTypeMap[item.messageTypeIndicator] }}
              </p>
            </template>
            <template v-slot:[`item.institution`]="{ item: { institution } }">
              <p class="pb-0 mb-0">{{ institution.taxIdentificationNumber }}</p>
              <b>{{ institution.name }}</b>
              <address :href="`tel: ${institution.contactNumber}`">
                {{
                  `${institution.street}, ${institution.city}, ${
                    institution.countrySubEntity
                  }, ${countryMap[institution.country]}`
                }}
              </address>
            </template>
            <template v-slot:[`item.user`]="{ item }">
              <div v-if="item.user">
                <span class="mr-2">{{ item.user.name }}</span> <br />
                <a :href="`tel: ${item.user.contactNumber}`">{{
                  item.user.contactNumber
                }}</a>
                <br />
                <a :href="`mailto: ${item.user.email}`">{{
                  item.user.email
                }}</a>
              </div>
            </template>
            <template v-slot:[`item.recipientCountry`]="{ item }">
              {{ countryMap[item.recipientCountry] }}
            </template>
            <template v-slot:[`item.actions`]="{ index, item }">
              <DataTableActions
                v-if="item.messageTypeIndicator === 'CRS703'"
                :actions="noDataActions"
                :data="{ index, item }"
              />
              <DataTableActions
                v-else
                :actions="fullActions"
                :data="{ index, item }"
              />
            </template>
          </v-data-table>
        </template>
      </DataTableLayout>
    </v-col>
  </v-row>
</template>

<style scoped>
</style>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
export default {
  mixins: [PageMixin],
  data: (vm) => ({
    search: '',
    confirmationTitle: '',
    confirmationMessage: '',

    layoutOptions: {
      hideSearch: false, // removes search button totally from view
      showSearch: false, // toggle search bar
      buttonText: 'Create Report',
      title: 'Common Standard Reports',
      subtitle: 'List of reports to be submitted.'
    },

    headers: [
      {
        text: 'Message ID',
        value: 'uniqueMessageIdentifier',
        filterable: true
      },
      { text: 'Message Type', value: 'messageTypeIndicator', filterable: true },
      { text: 'Sending Institution', value: 'institution', filterable: true },
      {
        text: 'Receiving Country',
        value: 'recipientCountry',
        filterable: true
      },
      { text: 'Created By', value: 'user', filterable: true },
      { text: 'Actions', align: 'end', value: 'actions', sortable: false }
    ],

    noDataActions: [
      {
        icon: 'mdi-xml',
        action: 'Download Report',
        actionCallBack: (index, item) => {
          console.log(vm, this);
          vm.downloadXMLReport(item._id);
        }
      },
      {
        icon: 'mdi-pail-remove-outline',
        action: 'Delete',
        actionCallBack: (index, item) => {
          vm.removeReport(item);
        }
      }
    ],
    fullActions: [
      {
        icon: 'mdi-table-large',
        action: 'View Accounts',
        actionCallBack: (index, item) => {
          const route = `/app/reports/${item._id}/accounts`;
          vm.$router.push(route);
        }
      },
      {
        icon: 'mdi-pencil-outline',
        action: 'Edit',
        actionCallBack: (index, item) => {
          vm.openForm(item);
        }
      },
      {
        icon: 'mdi-pail-remove-outline',
        action: 'Delete',
        actionCallBack: (index, item) => {
          vm.removeReport(item);
        }
      }
    ]
  }),

  async mounted() {
    this.actions = [];

    if (!this.user.institution) {
      this.fullActions = [];
      this.noDataActions = [];
      this.layoutOptions.hideButton = true;
    }

    try {
      await this.fetchReports(this.user.institution?._id);
    } catch (error) {
      this.httpErrorHandler(error);
    }
  },

  computed: {
    ...mapGetters('coreModule', [
      'messageTypeMap',
      'countryMap',
      'addressTypes'
    ]),
    ...mapGetters('reportModule', ['reports', 'mostCurrentReports'])
  },

  methods: {
    ...mapActions('reportModule', [
      'updateReport',
      'fetchReports',
      'deleteReport',
      'downloadXMLReport'
    ]),

    openForm(item) {
      if (!item) return this.$router.push('/app/report');

      this.$router.push(`/app/report/${item._id}`);
    },

    removeReport(item) {
      this.confirmationTitle = 'Are you sure you want to delete this report?';
      this.confirmationMessage =
        'This action will delete the report and all its data';

      this.$refs.activeToggleConfirmationModal.show(async () => {
        try {
          await this.deleteReport({ reportId: item.reportId });
          this.showSnack({
            color: 'info',
            title: `Removed`,
            message: `Report was successfully removed.`
          });
        } catch (error) {
          this.httpErrorHandler(error);
        }
      });
    }
  }
};
</script>